<template>
  <b-sidebar
    id="add-new-query-object-sidebar"
    :visible="isAddNewQueryObjectSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="500"
    @change="(val) => $emit('update:is-add-new-query-object-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- @hidden="resetForm" -->
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('DataAccess.QueryObjects.AddNew.newQueryObject') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
          ref="refNewQueryObject"
        >
          <!--ConnectionString field--->
          <validation-provider
            #default="validationContext"
            name="cadenaConexionId"
            rules="required"
          >
            <b-form-group
              :label="$t('DataAccess.QueryObjects.columns.connectionString')"
            >
              <v-select
                v-model="queryObjectData.cadenaConexionId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="connectionStrings"
                :reduce="option => option.id"
                @input="refetchQueryObjectColumns(queryObjectData.cadenaConexionId)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Name field --->
          <validation-provider
            #default="validationContext"
            name="nombre"
            rules="required"
          >
            <b-form-group
              :label="$t('DataAccess.QueryObjects.columns.name')"
            >
              <b-form-input
                v-model="queryObjectData.nombre"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--ObjetName field-->
          <validation-provider
            #default="validationContext"
            name="objetoNombre"
            rules="required"
          >
            <b-form-group
              :label="$t('DataAccess.QueryObjects.columns.objectName')"
            >
              <v-select
                v-model="queryObjectData.objetoConsulta"
                label="objetoConsultaNombreCompleto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="queryObjectDatabase"
                @input="onSelectQueryObject"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--Parametros de paginacion en funcion-->
          <b-form-group
            v-if="queryObjectData.objetoConsultaTipoId === 2"
            label="Paginacion"
          >
            <b-form-checkbox
              id="multipleValuesOption"
              v-model="queryObjectData.enFuncionPaginacionPorParametro"
              :value="true"
              :unchecked-value="false"
            >
              <strong>Por parametro</strong>
            </b-form-checkbox>
          </b-form-group>
          <!-- Form Actions -->
          <div class="float-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="isCreating"
            >
              {{ $t('Lists.Add') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  required,
} from '@validations'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import generalDataService from '@/services/generalData.service'

export default {
  components: {
    BSidebar,
    ValidationObserver,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    BFormCheckbox,

    vSelect,
  },
  props: {
    isAddNewQueryObjectSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* Services */
    const {
      createQueryObject,
      // fetchTypeObjectQuery
      fetchConnectionStringOptions,
      fetchQueryObjectDatabase,
    } = generalDataService()
    /* Data */
    const blankQueryObjectData = {
      cadenaConexionId: null,
      objetoConsultaTipoId: null,
      nombre: null,
      descripcion: null,
      esquema: null,
      objetoNombre: null,
    }
    const isCreating = ref(false)
    const queryObjectData = ref(JSON.parse(JSON.stringify(blankQueryObjectData)))
    const resetClassificationData = () => {
      queryObjectData.value = JSON.parse(JSON.stringify(blankQueryObjectData))
    }
    const queryObjectDatabase = ref([])
    const refetchQueryObjectColumns = cadenaConexionId => {
      fetchQueryObjectDatabase(data => {
        const { objetosConsulta } = data
        queryObjectDatabase.value = objetosConsulta
      }, cadenaConexionId)
    }
    const connectionStrings = ref([])
    fetchConnectionStringOptions(data => {
      connectionStrings.value = data
    })
    /* Events */
    const onSelectQueryObject = () => {
      queryObjectData.value.esquema = queryObjectData.value.objetoConsulta.esquema
      queryObjectData.value.objetoConsultaTipoId = queryObjectData.value.objetoConsulta.objetoConsultaTipoId
      queryObjectData.value.objetoNombre = queryObjectData.value.objetoConsulta.objetoConsultaNombre
    }

    const refNewQueryObject = ref(null)
    const onSubmit = () => {
      isCreating.value = true
      createQueryObject(queryObjectData.value)
        .then(() => {
          isCreating.value = false
          refNewQueryObject.value.reset()
          emit('update:is-add-new-query-object-sidebar-active', false)
          emit('refresh-data')
        })
    }

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClassificationData)

    return {
      // Data
      queryObjectData,
      // queryObjectTypes,
      connectionStrings,
      queryObjectDatabase,
      // Events
      onSelectQueryObject,
      onSubmit,
      refetchQueryObjectColumns,
      // Validations
      required,
      getValidationState,
      resetForm,
      refFormObserver,
      refNewQueryObject,
      isCreating,
    }
  },
}
</script>

<style>

</style>
